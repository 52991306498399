import { Locales } from '../../types/enums';
import {
  bodyStylesResponse,
  dealMakesResponse,
  dealsClaimOffer,
  fetchDealsPageListingPayload,
  fetchDealsPageListingResponse,
  OfferTypeResponse,
} from '../../types/models';
import { axiosNodeInstance } from '../api/interceptors';
import { CommonUtils, NewFilterUtils } from '../utilities';

export default class DealsOffersService {
  static fetchDealMakes = async (
    currentLocale: string = Locales.EN
  ): Promise<dealMakesResponse> => {
    try {
      const response = await axiosNodeInstance.get<dealMakesResponse>(
        `/deal-and-offer/deal-makes?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in DealsOffersService:fetchDealMakes`, error);
      throw error;
    }
  };

  static fetchBodyStyles = async (
    currentLocale: string = Locales.EN
  ): Promise<bodyStylesResponse> => {
    try {
      const response = await axiosNodeInstance.get<bodyStylesResponse>(
        `/master-data/body-styles?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in DealsOffersService:fetchBodyStyles`, error);
      throw error;
    }
  };

  static fetchOfferType = async (): Promise<OfferTypeResponse> => {
    try {
      const response = await axiosNodeInstance.get<OfferTypeResponse>(
        `/deal-and-offer/offer-type`
      );
      return await response?.data;
    } catch (error) {
      console.error(`Error in InvoiceService:fetchOfferType`, error);
      throw error;
    }
  };

  static fetchOffeListing = async (
    payload: fetchDealsPageListingPayload
  ): Promise<fetchDealsPageListingResponse> => {
    const filterQuery = NewFilterUtils.getDealsListingQueryString(payload);
    try {
      const response =
        await axiosNodeInstance.get<fetchDealsPageListingResponse>(
          `/deal-and-offer/listing?filters=${filterQuery}`
        );
      return response.data;
    } catch (error) {
      console.error(`Error in InvoiceService:fetchOfferType`, error);
      throw error;
    }
  };

  /**
   * This service is use to save Offer Claim Form.
   * @param {OfferClaimPayload}  [payload] - Offer Claim payload.
   */
  static saveOfferClaim = async (payload: dealsClaimOffer) => {
    try {
      const response = await axiosNodeInstance.post(
        `/deal-and-offer/deal-lead`,
        payload
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in DealsOffersService:saveOfferClaim`, error);
      throw error;
    }
  };

  static fetchDealSetting = async (
    currentLocale: string = Locales.EN
  ): Promise<any> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/deal-setting?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return await response.data.data;
    } catch (error) {
      console.error(`Error in DealsOffersService:fetchDealSetting`, error);
      throw error;
    }
  };
  static fetchDealSettingV2 = async (
    currentLocale: string = Locales.EN
  ): Promise<any> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/v2/deal-setting?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in DealsOffersService:fetchDealSettingV2`, error);
      throw error;
    }
  };
}
